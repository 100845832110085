import React, { useEffect ,useState, useContext} from 'react';
import { Card } from 'antd';
import { Button, Form, Input } from 'antd';
import "./Profile.css";
import { SideMenuContext } from '../context/sidemenu';
import { initializePaddleInstance, openPaddleCheckout } from "../components/PaddleLoader";
import { API, Auth } from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';

export default function Profile(){
    const [emailId, setEmailId]=useState("");
    const {setSelectedMenuItem } = useContext(SideMenuContext);
    const [paddle, setPaddle] = useState();
    const [credits, setCredits] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [profileInfo, setProfileInfo] = useState({
        "firstName":"",
        "lastName":"",
        "affiliation":"",
        "contactNumber":"",
        "address":"",
        "country":"",
        "postalCode":"",
    });
    const [form] = Form.useForm();
    async function tokenLoader() {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const emailId=user.username
        return [token,emailId];
    };

    async function userData() {
        const [, emailId]= await tokenLoader();
        setEmailId(emailId)
    };

    async function userProfileData(){
        const[token,] = await tokenLoader();
        try{
            const request = {
                headers:{
                    Authorization : "Bearer "+token
                }
            };
            const responseProfileInfo = await API.get("diadem",'/user/userProfileInfo',request);
            const profileInfoDict=responseProfileInfo["body"];
            let updatedProfileInfo={
                "firstName": profileInfoDict["firstName"] || "",
                "lastName": profileInfoDict["lastName"] || "",
                "affiliation": profileInfoDict["affiliation"] || "",
                "contactNumber": profileInfoDict["contactNumber"] || "",
                "address": profileInfoDict["address"] || "",
                "country": profileInfoDict["country"] || "",
                "postalCode": profileInfoDict["postalCode"] || "",

            }
            setProfileInfo(updatedProfileInfo);
            form.setFieldsValue(updatedProfileInfo);
        }
        catch(error){
            toast.error("Error fetching profile information",error);
        }

    };

    async function fetchCredits() {
        const [token,]= await tokenLoader();
        try {
            const request = {
                headers: {
                    Authorization: "Bearer " + token
                }
            };
            const jsonDataUser= await API.get("diadem",'/user/userInfo', request);
            setCredits(jsonDataUser["body"]["credits"]);
        } catch (error) {
          console.error('Error fetching credits:', error);
        }
      };

    useEffect(()=> {userData()
        setSelectedMenuItem("profile")
        initializePaddleInstance().then((paddleInstance) => {
            setPaddle(paddleInstance);
            });
        fetchCredits();
        userProfileData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);

    const onFinish = async (values) => {
        setIsLoading(true)
        const [token,]= await tokenLoader();
        try {
            const request = {
                headers: {
                    Authorization: "Bearer " + token
                },
                body: values
            };
            const jsonDataUser= await API.put("diadem",'/user/profileInfo', request);
            if (jsonDataUser.statusCode===200){
                setIsLoading(false);
                toast.success(jsonDataUser["body"]["message"]);
              }
            else{
            const body=JSON.parse(jsonDataUser.body)
            if (body && body.exception){
                toast.error(body.exception);
                setIsLoading(false);
            }
            }
        }
        catch (error) {
            console.error('Error submitting information:', error);
            }
      };

    const onFinishFailed = (errorInfo) => {
      };

    const handleChange = (changedValues, allValues) => {
        setProfileInfo(prevState => ({
            ...prevState,
            ...changedValues,
        }));
    };
    return (
        <>
        <ToastContainer position="top-center" />
        {isLoading ? (
        <div class="loading" id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      ) : (
          <>
          </>
    )}
        <div className="Profile">
            <h1>Profile</h1>

            <div className="container-up">

                    <Card
                        className="account-card"
                        title="Account Information"
                        bordered={false}
                        style={{
                        width: 300,
                        }}
                    >
                        <Form>
                        <p>User email : {emailId}</p>
                        <p>Subcription : Basic Subscription</p>

                        {credits !== null ? (
                        <p>Current Credits: {credits}</p>
                        ) : (
                            <p>Loading credits...</p>
                            )}

                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 16,
                            }}
                            >
                            <Button type="primary" onClick={() => openPaddleCheckout(paddle, 'pri_01hkm8rz010346rpt0xetsecnb',emailId)}>
                            Buy Credits
                            </Button>
                            </Form.Item>
                        </Form>

                    </Card>
                    <Card
                        className="personal-card"
                        title="Personal Information"
                        >
                        <Form
                            form ={form}
                            className="personal-info"
                            name="basic"
                            labelCol={{
                            span: 8,
                            }}
                            wrapperCol={{
                            span: 16,
                            }}
                            style={{
                            maxWidth: 600,
                            }}
                            initialValues={{
                            // remember: true,
                            firstName: profileInfo.firstName,
                            }}
                            onValuesChange={handleChange}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                            label=" First Name"
                            name="firstName"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your first name!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>
                            <Form.Item
                            label=" Last Name"
                            name="lastName"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your last name!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>

                            <Form.Item
                            label="Affiliation"
                            name="affiliation"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your affiliation!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>

                            <Form.Item
                            label="Contact Number"
                            name="contactNumber"
                            rules={[
                                {
                                required: false,
                                message: 'Please input your contact number!',
                                },
                            ]}
                            >
                            <Input />

                            </Form.Item>


                            <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            </Form.Item>
                        </Form>
                    </Card>

            </div>
            <Card
                className="shipping-card"
                title="Shipping Information"
            >
            <Form
                form ={form}
                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Address"
                name="address"
                rules={[
                    {
                    required: true,
                    message: 'Please input your address!',
                    },
                ]}
                >
                <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                label="Country/Region"
                name="country"
                rules={[
                    {
                    required: true,
                    message: 'Please input your country!',
                    },
                ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                label="Postal code"
                name="postalCode"
                rules={[
                    {
                    required: true,
                    message: 'Please input your postal code!',
                    },
                ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>
            </Form>
            </Card>
        </div>
        </>
      );
}
