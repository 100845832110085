import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Input, Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import "./ForgotPassword.css";
import logo from "../assets/logo2.png";
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [passwordError, setPasswordError] = useState('');
  const nav = useNavigate();

  const sendResetCode = async () => {
    try {
      await Auth.forgotPassword(email);
      toast.success('Reset code sent successfully!');
      setStep(2);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const resetPassword = async () => {
    const error = validatePassword(newPassword);
    if (error) {
      setPasswordError(error);
      return;
    }
    try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        toast.success('Password reset successfully!');
        setTimeout(() => {
            nav('/login');
        }, 4000);

    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  function validatePassword(password) {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) return 'Password must be at least 8 characters long.';
    if (!hasUppercase) return 'Password must have at least one uppercase letter.';
    if (!hasLowercase) return 'Password must have at least one lowercase letter.';
    if (!hasNumber) return 'Password must have at least one number.';
    if (!hasSpecialChar) return 'Password must have at least one special character.';
    return null;
  }

  return (
    <>
    <ToastContainer position="top-center" />
    <div  className="outer">
        <div className="logo-bar">
            <div className="logo-image">
                <img src={logo} alt="logo" className="logo-image"></img>
            </div>
        </div>
        <div className="label-bar">
            <div className="label-heading">Reset your password
            </div>
        </div>
        {step === 1 && (
            <Form
            onFinish={sendResetCode}
            layout="vertical"
            name="forgot_password"
            >
            <Form.Item
                label="Email address"
                name="email"
                rules={[{ required: true, message: 'Please input your Email!' }]}
            >
                <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email"
                className='form-control-forgot'
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit"  className="reset-button">
                Send Reset Code
                </Button>
            </Form.Item>
            </Form>
        )}

        {step === 2 && (
            <Form onFinish={resetPassword} layout="vertical" name="reset_password">
            <Form.Item
                label="Verification Code"
                name="code"
                rules={[{ required: true, message: 'Please input the verification code!' }]}
            >
                <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter verification code"
                className='form-control-forgot'
                />
            </Form.Item>
            <Form.Item
                label="New Password"
                name="newPassword"
                rules={[{ required: true, message: 'Please input your new password!' }]}
            >
                <Input.Password
                value={newPassword}
                onChange={(e) => {
                    setNewPassword(e.target.value);
                    setPasswordError('');
                  }}
                placeholder="Enter new password"
                className='form-control-forgot'
                />
            </Form.Item>
            {passwordError && (
                <div style={{ color: 'red', marginBottom: '5px' }}>
                  {passwordError}
                </div>
              )}
            <Form.Item>
                <Button type="primary" htmlType="submit" className="reset-button">
                Reset Password
                </Button>
            </Form.Item>
            </Form>
        )}
        </div>
    </>
  );
}

export default ForgotPassword;
