import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QueryResultChild from "./QueryResultChild";
import QueryResultBarChild from "./QueryResultBarChild";
import QueryResultRefineChild from "./QueryResultRefineChild";
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';
import { API, Auth } from "aws-amplify";

function QueryResult(){
    const {queryId}=useParams();
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const [isLoading, setIsLoading] = useState({});
    const [dataResult, setDataResult] = useState({});
    const [statusCode, setStatusCode] = useState({});
    async function handlePastResults () {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body : queryId,
        };
        const jsonDataResult= await API.put("diadem", "/search/property-history", request);
        setDataResult(JSON.stringify(jsonDataResult["body"]));
        let statusCodeResult=jsonDataResult["statusCode"]
        if (statusCodeResult===200){
            setStatusCode(true);}
        else{
            setStatusCode(false);}
        setIsLoading(false);
    }
    // eslint-disable-next-line
    useEffect(() => {
        setSelectedMenuItem("search"); // eslint-disable-line
        handlePastResults(); // eslint-disable-line
    }, []); // eslint-disable-line


    return (
        <>
            {isLoading ? (
                    <div class="loading" id="loading-wrapper">
                    <div id="loading-text">LOADING</div>
                    <div id="loading-content"></div>
                    </div>
                ) :
                <>
                    {statusCode ? (
                        <>
                            <div>
                                <h1>Results</h1>
                                <div className="result-banner-container">
                                    <QueryResultRefineChild resultBody={dataResult}></QueryResultRefineChild>
                                    <QueryResultBarChild resultBody={dataResult}></QueryResultBarChild>
                                </div>
                                <QueryResultChild resultBody={dataResult}></QueryResultChild>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <h1>Results not found, Please refine query</h1>
                            </div>
                        </>
                    )}
                </>
            }
        </>
    )
}

export default QueryResult;
