import React, { useEffect, useState, useContext } from 'react';
import { API, Auth } from "aws-amplify";
import QueryChild from "./QueryChild";
import { SideMenuContext } from '../context/sidemenu';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "./QueryParent.css"

function QueryParent() {
  const [dataPCT, setDataPCT] = useState([]);
  const [dataCalc, setDataCalc] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const { setSelectedMenuItem } = useContext(SideMenuContext)
  const navigate = useNavigate();

  async function dataPctCalc() {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const request = {
        headers: {
            Authorization: "Bearer " + token
        }
    };
    const jsonDataPCT= await API.get("diadem", "/proptype/list", request);
    setDataPCT(jsonDataPCT["body"]);
    const jsonDataCalc= await API.get("diadem", "/calculator/list", request);
    setDataCalc(jsonDataCalc["body"]);
    setLoading(false);
  }

  async function navigatePastSearches (event) {
    navigate("/queryHistory");
  }


  // eslint-disable-next-line
  useEffect(() => {
    dataPctCalc(); // eslint-disable-line
    setSelectedMenuItem("search-property"); // eslint-disable-line
  }, []); // eslint-disable-line

  return (
    <div className="App">
      <ToastContainer position="top-center" />
      <div className='search-head-container'>
        <h1>Search</h1>
        <Button className="past-searches" type="primary" onClick={navigatePastSearches} >Past Searches</Button>
      </div>
      <QueryChild dataPCT={dataPCT} dataCalc={dataCalc}></QueryChild>
    </div>
  );
}

export default QueryParent;
