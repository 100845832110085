import React from 'react';
import { Card, Button, Col, Row } from 'antd';
import "./QueryResultRefineChild.css";
import { ToastContainer, toast } from 'react-toastify';

const QueryResultRefineChild = ({resultBody}) => {
    const parsedResultBody=JSON.parse(resultBody)
    const queryList=parsedResultBody["query"]
    const refineSearch = (event) => {
      toast.info("It can be refined from query search page!");
    };
    return (
      <>
        <ToastContainer position="top-center" />
        <Card title="Query Specifications" bordered={true} style={{ width: 450 }} className="query-specifications-card">
          <Row>
            <Col span={4}><b>Property</b></Col>
            <Col span={4}><b>Operator</b></Col>
            <Col span={3}><b>Value</b></Col>
            <Col span={5}><b>Calculator</b></Col>
            <Col span={3}><b>Version</b></Col>
          </Row>
         {queryList.map((item) => (
            <Row>
              <Col span={4}>{item["propertyName"]}</Col>
              <Col span={4}>{Object.keys(item).includes('valueOperator')?(
                <span>{item["valueOperator"]}</span>):(<span>Any</span>)}</Col>
              <Col span={3}>{Object.keys(item).includes('value')?(
                <span>{`${item["value"]} ${item["unit"]}`}</span>):(<span>Any</span>)}</Col>
              <Col span={5} className='calc-name-wwrap'>{Object.keys(item).includes('calculatorName')?(
                <span>{item["calculatorName"]}</span>):(<span>Any</span>)}</Col>
              <Col span={3}>{Object.keys(item).includes('calculatorVersion')?(
                <span>{item["calculatorVersion"]}</span>):(<span>Any</span>)}</Col>
            </Row>
          ))}
          <Button type="primary" className="refine-btn" onClick={refineSearch}>
              Refine
          </Button>
        </Card>
      </>
    )

}
export default QueryResultRefineChild;
