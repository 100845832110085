import React, { useState, useContext } from 'react';
import { Button, Space} from 'antd';
import { Table } from "ant-table-extensions";
import { Link} from "react-router-dom";
import "./QueryResultChild.css"
import { onDemandMoleculesContext } from '../context/onDemandMolecules'
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { CartContext } from '../context/cart';
import { toast } from 'react-toastify';
import { Auth, API } from 'aws-amplify';

const QueryResultChild = ({resultBody}) => {
    const { addMultipleToOnDemandMoleculesList } = useContext(onDemandMoleculesContext)
    const nav = useNavigate();
    const { addToCartMultiple } = useContext(CartContext);
    const columns = [
      {
        title: 'InChIKey',
        dataIndex: 'molId',
        render: (molId) => <Link to={`/moleculeView/${molId}`} >{molId}</Link>
      },
        {
          title: 'Smiles',
          dataIndex: 'smiles',
          render: (smiles,row) => <Link to={`/moleculeView/${row.molId}`} >{smiles}</Link>
        }
      ];
    const expandColumns = [
          {
            title: 'Property Name',
            dataIndex: 'propertyName',
          },
          {
            title: 'Value',
            dataIndex: 'value',
          },
          {
            title: 'Calculator',
            dataIndex: 'calculatorName',
          },
          {
            title: 'Calculator Version',
            dataIndex: 'version',
          },
        ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCart, setLoadingCart] = useState(false);
    const data = [];

    const parsedResultBody=JSON.parse(resultBody)
    const onDemand = (event) => {
      let selctedMolecules=[]
      selectedRowKeys.map((itemKey) => {
        selctedMolecules.push(data[itemKey])
        return null;
      }
      )

      let alreadyExistLength= addMultipleToOnDemandMoleculesList(selctedMolecules);


      if (alreadyExistLength > 0){
        toast.warn(`${alreadyExistLength} molecules already exist for on-demand calculation`)
      }
      else{
        toast.success("Molecules are added for on-demand calculation successfully")
        }
      setLoading(true);
      setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
        nav("/calculationSetUp")
      }, 3000);

    };
    const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    const colPropertyList=parsedResultBody["result"]
    const resultLength=colPropertyList.length
    let csvData = [];
    for (let i = 0; i < selectedRowKeys.length; i++) {
      let selectedItem = colPropertyList[i];
      let selectedItemDetails = selectedItem["details"]
      for (let j = 0; j < selectedItemDetails.length; j++){
        let tempDict = {};
        tempDict['InChiKey'] = selectedItem["molId"];
        tempDict['Smiles'] = selectedItem["smiles"];
        tempDict['Property Name'] = selectedItemDetails[j]["propertyName"];
        tempDict['Value'] = selectedItemDetails[j]["value"];
        tempDict['Unit'] = selectedItemDetails[j]["unit"];
        tempDict['Calculator Name'] = selectedItemDetails[j]["calculatorId"];
        tempDict['Calculator Version'] = selectedItemDetails[j]["version"];
        csvData.push(tempDict);
      };
    };

    const hasSelected = selectedRowKeys.length > 0;

    const notEmpty=(Object.keys(colPropertyList).length !== 0)
    let expandData = [];
    if (notEmpty){
      for (let i = 0; i < resultLength; i++) {
        data.push({
          key:i,
          molId: colPropertyList[i]["molId"],
          smiles: colPropertyList[i]["smiles"]
        });
        let detailList=colPropertyList[i]["details"];
        detailList.forEach((detailDict) => {
          expandData.push({
            key: i,
            propertyName: detailDict["propertyName"],
            value:`${ Number(detailDict["value"]).toFixed(2)} ${detailDict["unit"]}`,
            calculatorName: detailDict["calculatorId"],
            version: detailDict["version"]
          });
        });
      }
    }

    const addMoleculesToCart = async (event) => {
      let selectedItemsLength= selectedRowKeys.length;
      if (selectedItemsLength>10){
        toast.warn("Please select up to 10 molecules at a time. You can add more after these are added to the cart.")
      }
      else{
        setLoadingCart(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const molListPromises = selectedRowKeys.map(async i => {
          const molId = colPropertyList[i]["molId"];
          const molBody={"molId":molId};
          const jsonDataPC=await dataMoleculePC(token, molBody);
          return jsonDataPC["body"]["molecule"]
        });
        const molListResult = await Promise.all(molListPromises)
        addToCartMultiple(molListResult);
        toast.success("Molecules added to cart successfully!");
        setLoadingCart(false);

      }

    };

    async function dataMoleculePC(token, molBody) {
      const request = {
          headers: {
              Authorization: "Bearer " + token
          },
          body : molBody,
      };
      const jsonDataPC= await API.put("diadem", "/molecule/properties", request);
      return jsonDataPC
    }

    let expandedRowRender = (record,i) => {
      let filteredList = expandData.filter(item => item.key === i);
      return <Table columns={expandColumns} dataSource={filteredList} pagination={false} />
    };
    // temp function to display notification for placeholders
    function showMessage(){
      toast.info("Thank you for your patience! This feature is coming soon.")
    }
    return (
      <div>
        <div className="result-table-btn-container">
          <Space wrap>
            <Button type="primary" onClick={onDemand} disabled={!hasSelected} loading={loading}>
              Run on demand calculation
            </Button>
            <Button type="primary" loading={loadingCart} disabled={!hasSelected} onClick={addMoleculesToCart}>Add to cart</Button>
            <Button type="primary" onClick={showMessage} disabled={!hasSelected} loading={loading}>
              Compare molecules
            </Button>
            <Button type="primary" loading={loading}>
            <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
            Export to CSV
          </CSVLink>
          </Button>
          </Space>
          <span className="selected-text" >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table rowSelection={rowSelection}  expandable={{expandedRowRender,
          defaultExpandedRowKeys: ['0'],}}  columns={columns} dataSource={data} scroll={{ x: 400 }} searchable/>
      </div>
    );
};
export default QueryResultChild;
