import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import "./Footer.css";
import logo from "../assets/logo1.png";

export default function Footer() {
    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
        </div>

        <div>
            {/* <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
            </a>
            <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
            </a>
            <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
            </a> */}
            <a href='https://www.linkedin.com/company/diadem-project/posts/?feedView=all' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
            </a>
            {/* <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
            </a> */}
        </div>
        </section>

        <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
            <div className="footer-outer">
                <>
                <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                        <div className='brand-container'>
                            <div>
                            <img src={logo} alt="logo" className="navbar-logo"></img>
                            </div>
                            <div  className="brand-name">
                                Diadem
                            </div>
                        </div>
                        <p>
                        Diadem is a digital discovery platform for organic electronic materials.
                        </p>
                </MDBCol>
                </>
                <>
                <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                    <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                    <p>
                    <a href='https://www.diadem-project.eu/' className='text-reset'>
                    DiaDEM Project
                    </a>
                    </p>

                </MDBCol>
                </>
                <>
                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                    <p>
                    <MDBIcon icon="home" className="me-2" />
                    Griesbachstr. 5 <br/>
                    76185 Karlsruhe <br/>
                    Baden-Württemberg <br/>
                    Germany
                    </p>
                    <p>
                    <MDBIcon icon="envelope" className="me-3" />
                    diadem-staging@nanomatch.com
                    </p>
                    <p>
                    <MDBIcon icon="phone" className="me-3" /> +49 721 95965454
                    </p>
                </MDBCol>
                </>
            </div>
            </MDBRow>
        </MDBContainer>
        </section>

        <div className='text-center p-4 footer-base'>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='https://diadem-staging.de/'>
        diadem-staging.de
        </a>
        </div>
        </MDBFooter>
);
}
