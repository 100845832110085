import React, { useState, useEffect } from 'react';
import { QueryBuilder , formatQuery, defaultOperators} from 'react-querybuilder';
import { QueryBuilderAntD, AntDActionElement } from '@react-querybuilder/antd';
import CustomValueEditor from './CustomValueEditor';
import 'react-querybuilder/dist/query-builder.css';
import './styles.scss';
import { API, Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import "./QueryChild.css";
import { toast } from 'react-toastify';

const initialQuery = { combinator: 'and', rules: [] };

const QueryChild = ({dataPCT, dataCalc}) => {
  const [query, setQuery] = useState(initialQuery);
  const [units, setUnits] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const propDataCalcVersion=[]
  const calculatorMap = {};
  const propDataCalc = [];

  const propDataPct=dataPCT.reduce((acc,item)=>{
    if (item.isSearchable){
      acc.push({name: item.name, label: item.name });
    }
    return acc;
  },[]);

  dataCalc.forEach((item) => {
    if (calculatorMap.hasOwnProperty(item.calculatorId)){
      const tempArr = calculatorMap[item.calculatorId];
      tempArr.push(item.version);
      calculatorMap[item.calculatorId] = tempArr;
    }
    else{
      const tempArr = [];
      tempArr.push(item.version);
      calculatorMap[item.calculatorId] = tempArr;
    }

  })
  for (const [key, value] of Object.entries(calculatorMap)) {
    const tempArr = [];
    value.map((item) => (
      tempArr.push( { name: item, label: item} )
    ));
    propDataCalc.push({ name: key, versions: tempArr});
  }
  dataCalc.map((item) => (
    propDataCalcVersion.push( { name: item.version, label: item.version} )
  ))

  useEffect(() => {
    const fetchUnit = async (propertyName, groupId) => {
      if (!propertyName) return;

      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
          body: propertyName,
          headers: {
            Authorization: "Bearer " + token
          }
        };
        const response = await API.put("diadem", "/propType/propName", request);
        setUnits(prevUnits => ({
          ...prevUnits,
          [groupId]: response.body.unit
        }));

      } catch (error) {
        console.error("Error fetching unit:", error);
        setUnits(prevUnits => ({
          ...prevUnits,
          [groupId]: ''
        }));
      }
    };

    // Iterate over all groups and fetch units based on their rules
    if (Array.isArray(query.rules)) {
      query.rules.forEach(group => {
        if (Array.isArray(group.rules)) {
          const selectedRule = group.rules.find(subRule => subRule.field === 'propertyName');
          if (selectedRule && selectedRule.value) {
            fetchUnit(selectedRule.value, group.id);
          } else {
            setUnits(prevUnits => ({
              [group.id]: ''
            }));
          }
        }
      });
    }
  }, [query.rules]);

  useEffect(() => {
    Object.entries(units).forEach(([groupId, textContent]) => {
        const parentRuleGroupDiv = document.querySelector(`div[data-rule-group-id="${groupId}"]`);

        if (parentRuleGroupDiv) {
          const spanElement = parentRuleGroupDiv.querySelector(`span.ant-select-selection-item[title="Value"]`);

          if (spanElement) {
            const parentRuleDiv = spanElement.closest('div[data-testid="rule"].rule');

            if (parentRuleDiv) {
              let existingDiv = parentRuleDiv.querySelector('.dynamic-unit-div');

              if (existingDiv) {
                existingDiv.textContent = textContent;
              } else {
                const newDiv = document.createElement('div');
                newDiv.textContent = textContent;
                newDiv.className = 'dynamic-unit-div';
                parentRuleDiv.appendChild(newDiv);
              }
            }
          }
        }

    });
  }, [units]); // Re-run the effect if divData changes

  const validator = (q) => {
    if(q.rules.length !== 0){
      return ({
        id: { valid: false,
        reasons: ['this field is always invalid']
        }
      })
    }
    return ({
    valid: false,
    reasons: ['this field is always invalid'],
  })
};

  const fields = [
    {
      name: 'propertyName',
      label: 'Property Name',
      valueEditorType: 'select',
      values: propDataPct,
      datatype: 'text'
    },
    { name: 'value',
      label: 'Value',
      datatype: 'number',
      inputType: 'number'
    },
    {
      name: 'calculator',
      label: 'Calculator',
      valueEditorType: 'select',
      values: propDataCalc,
      datatype: 'text'
    },
  ];

  const getOperators = (fieldName) => {
    const field = fields.find((fld) => fld.name === fieldName);
    switch (field.datatype) {
        case 'text':
        return [
            { name: '=', label: 'equal' },
        ];
        case 'number':
        return [
            { name: '<', label: 'less than' },
            { name: '<=', label: 'less than or equal to' },
            { name: '>', label: 'greater than' },
            { name: '>=', label: 'greater than or equal to' },
            { name: 'between', label: 'between' }
        ];
        default:
        return defaultOperators;
    }
    };

  const ruleValidator = (props) => {
    if(props.level === 0){
      return null;
    }
    if (props.level === 1){
      if (props.rules.length >= 3){
        return null;
      }
    }
    if (props.level > 1){
      return null;
    }
    return (<AntDActionElement {...props}/>)
  }

  const groupValidator = (props) => {
    if(props.level === 0){
      return <AntDActionElement {...props}/>;
    }
    return null;
  }

  const combinatorValidator = (props) => {
    return null;
  }

  const validateQuery = (query) => {
    if (!query.rules || query.rules.length === 0) {
      toast.error("At least one group must be added.");
      return false;
    }
    for (const rule of query.rules) {
      if (rule.combinator && rule.rules && rule.rules.length > 0) {
        if (!validateQuery(rule)) {
          return false;
        }
      } else {
        if (!rule.field || rule.field.trim() === '') {
          toast.error("Field cannot be empty.");
          return false;
        }
        if (!rule.value || rule.value.toString().trim() === '') {
          toast.error("Value cannot be empty.");
          return false;
        }
        if (rule.field === 'calculator') {
          if (!rule.value || !Array.isArray(rule.value)) {
            toast.error("Both Calculator and Version must be selected.");
            return false;
          }

          const [calculator, version] = rule.value;
          if (!calculator || calculator.trim() === '') {
            toast.error("Calculator must be selected.");
            return false;
          }

          if (!version || version.trim() === 'T') {
            toast.error("Version must be selected.");
            return false;
          }
        }

      }
    }

    return true;
  };




  async function handleSubmit (event) {
    setIsLoading(true)
    const queryJson=formatQuery(query, 'json')
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
        body: queryJson,
        headers: {
            Authorization: "Bearer " + token
        }
    };
    const jsonDataPCT= await API.put("diadem", "/search/property-new", request);
    if (jsonDataPCT.statusCode===200){
      setIsLoading(false);
      navigate("/queryResult",{state:{data:jsonDataPCT}});
    }
    else{
      const body=JSON.parse(jsonDataPCT.body)
      if (body && body.exception){
        toast.error(body.exception);
        setIsLoading(false);
      }
    }

  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (event) => {
    if (!validateQuery(query)) {
      setIsLoading(false);
      return;
    }
    event.preventDefault();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    handleSubmit();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    {isLoading ? (
        <div class="loading" id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      ) : (
          <>
          </>
    )}

    <div>
      <QueryBuilderAntD>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={(q) => setQuery(q)}
          validator={validator}
          getOperators={getOperators}
          controlElements={{
            addRuleAction: ruleValidator,
            addGroupAction: groupValidator,
            combinatorSelector: combinatorValidator,
            valueEditor: CustomValueEditor
           }}

        />
      </QueryBuilderAntD>
      <Button className="query-submit" type="primary" onClick={showModal} >Submit Query</Button>
      <Modal title="Please verify" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>This query search will deduct one credit. Do you want to proceed? </p>
      </Modal>
    </div>
    </>
  );
};

export default QueryChild;
