import React, { useState, useEffect, useContext} from "react";
import { useParams} from "react-router-dom";
import { SmiDrawer } from "smiles-drawer";
import "./MoleculeView.css";
import {Button, Card, Typography } from 'antd';
import { API, Auth } from "aws-amplify";
import { Table } from "ant-table-extensions";
import { CartContext } from '../context/cart';
import { ToastContainer, toast } from 'react-toastify';
import { SideMenuContext } from '../context/sidemenu';
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { onDemandMoleculesContext } from '../context/onDemandMolecules';

const {Paragraph} = Typography;

export default function MoleculeView(){
    const [dataMolecule, setDataMolecule] = useState({});
    const { addMultipleToOnDemandMoleculesList } = useContext(onDemandMoleculesContext)
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const { addToCart } = useContext(CartContext);
    const { setSelectedMenuItem } = useContext(SideMenuContext)
    const {molId}=useParams();
    const [smiles, setSmiles] = useState('');
    const nav = useNavigate();

    const columns = [
        {
          title: 'Property',
          dataIndex: 'propertyName',
        },
        {
          title: 'Calculator',
          dataIndex: 'calculatorName',
        },
        {
          title: 'Calculator Version',
          dataIndex: 'version',
        },
        {
          title: 'Value',
          dataIndex: 'value',
        },
      ];

    async function dataMoleculePC() {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const molBody={"molId":molId}
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body : molBody,
        };
        const jsonDataPC= await API.put("diadem", "/molecule/properties", request);
        setSmiles(jsonDataPC["body"]["molecule"]["smiles"])
        setDataMolecule(jsonDataPC["body"]);
        setIsLoading(false);

    }
   // eslint-disable-next-line
    useEffect(() => {
        dataMoleculePC(); // eslint-disable-line
        setSelectedMenuItem("search"); // eslint-disable-line
    }, []); // eslint-disable-line

    useEffect(() => {
        SmiDrawer.apply(
            // {width: 200, height:200}
        ); // eslint-disable-line
        // After rendering the SMILES, adjust the viewBox of the generated SVG
        // const svgElement = document.querySelector('.molsvg-container svg');
        // if (svgElement) {
        //     svgElement.setAttribute('viewBox', '-54.11236572265625 -64.31272525648103 280.58697474002838 280.58697474002838');
        // }
    }, [dataMolecule]); // eslint-disable-line

    const onDemand = (event) => {
        let selctedMolecules=[]
        selctedMolecules= [{"molId": molId,"smiles":smiles}]
        addMultipleToOnDemandMoleculesList(selctedMolecules);
        nav("/calculationSetUp")
      };

    const notEmpty=(Object.keys(dataMolecule).length !== 0)
    const data = [];
    const csvData=[];
    if(Object.keys(dataMolecule).length !== 0){
        const moleculePropertiesList=dataMolecule["moleculeProperties"];
        const resultLength=moleculePropertiesList.length;
        for (let i = 0; i < resultLength; i++) {
            const valueType=dataMolecule["moleculeProperties"][i]["valueType"]
            let valueProperty;
            if (valueType === "num") {
                if ('value' in moleculePropertiesList[i]) {
                    valueProperty = Number(moleculePropertiesList[i]["value"]).toFixed(2);
                }
            } else if (valueType === "bool") {
                if ('valueBool' in moleculePropertiesList[i]) {
                    valueProperty = moleculePropertiesList[i]["valueBool"] ? 'True' : 'False';
                }
            } else if (valueType === "string") {
                if ('valueString' in moleculePropertiesList[i]) {
                    valueProperty = moleculePropertiesList[i]["valueString"];
                }
            }
            data.push({
                key:i,
                propertyName: moleculePropertiesList[i]["propertyTypeName"],
                calculatorName: moleculePropertiesList[i]["calculatorId"],
                version: moleculePropertiesList[i]["version"],
                value: `${valueProperty} ${moleculePropertiesList[i]["unit"]}`,
            });
        }
    }

    const addMolToCart = (event) => {
        addToCart(dataMolecule.molecule);
        toast.success("Molecule added to cart successfully!");
    };
    // temp function to display notification for placeholders
    function showMessage(){
      toast.info("Thank you for your patience! This feature is coming soon.")
    }

    const resultLength=data.length;
    const moleculePropertiesList=dataMolecule["moleculeProperties"];
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            csvData.push({
                key:i+1,
                propertyName: data[i]["propertyName"],
                calculatorName: data[i]["calculatorName"],
                version: data[i]["version"],
                value: data[i]["value"],
                unit: moleculePropertiesList[i]["unit"]
            });
        }
      }

    return(
        <>
            <ToastContainer position="top-center" />

            {notEmpty ? (
                <div>
                    <h1>Molecule Details</h1>
                    <div className="molecule-banner-container">
                        <div className="molsvg-container">
                            <svg data-smiles={dataMolecule["molecule"]["smiles"]} />
                        </div>
                        <Card title="Molecule Details" bordered={true} className="molecule-card">
                            <Paragraph
                                ellipsis={{ rows: 1, expandable: true }}
                                className="smiles-expand"
                            >
                                Smiles: {dataMolecule.molecule.smiles}
                            </Paragraph>
                            <p>InChIKey: {dataMolecule.molecule.inchiKey}</p>
                        </Card>

                    </div>
                    {/* {sd.draw(dataMolecule["molecule"]["smiles"], '#molSvg')} */}
                    <div className="mol-detail-btn-container">
                        <Button type="primary" onClick={showMessage}>Find similar molecules</Button>
                        <Button type="primary" onClick={onDemand}>Run on-demand calculation</Button>
                        <Button type="primary" onClick={addMolToCart}>Add to cart</Button>
                        <Button type="primary" >
                            <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
                                Export to CSV
                            </CSVLink>
                        </Button>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={data} />
                    </div>

                </div> ) :
            (
                <div class="loading" id="loading-wrapper">
                    <div id="loading-text">LOADING</div>
                    <div id="loading-content"></div>
                </div>
            )}


        </>
    );
}
