import { createContext, useState, useEffect } from 'react'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
// TODO :: After M18,instead of localStorage, use database since localStorage is less secure
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])
  const addToCart = (item) => {
    let uniqueId = item.inchiKey;
    const updatedCartItems = [...cartItems];
    uniqueId+=generateUniqueId()
    updatedCartItems.push({ ...item,
      quantity: 1,
      priceList: item.priceList,
      id: uniqueId
    });
    setCartItems(updatedCartItems);
  };
  // below code unique molecules in cart, keeping it until budapest meeting, yet to decide
  // const addToCartMultiple = (items) => {
  //   const updatedCartItems = [...cartItems];
  //   items.forEach((item) => {
  //     const isItemInCart = updatedCartItems.find(
  //       (cartItem) => cartItem.inchiKey === item.inchiKey
  //     );

  //     if (isItemInCart){
  //       updatedCartItems.forEach((cartItem, index) => {
  //         if (cartItem.inchiKey === item.inchiKey) {
  //           // updatedCartItems[index] = { ...cartItem, quantity: cartItem.quantity + 1 };
  //           updatedCartItems[index] ={ ...cartItem,
  //             quantity: cartItem.quantity + 1,
  //             priceList: cartItem.priceList
  //           };
  //         }
  //       });
  //     }
  //    else {
  //       updatedCartItems.push({ ...item,
  //         quantity: 1,
  //         priceList: item.priceList
  //       });
  //     }
  //   });
  //   setCartItems(updatedCartItems);
  // };


  const generateUniqueId = () => {
    return `-id-${Date.now()}-${Math.floor(Math.random() * 100000)}`;
  };
  // below code for duplicate molecules in the cart
  const addToCartMultiple = (items) => {
    const updatedCartItems = [...cartItems];
    items.forEach((item) => {
      let uniqueId = item.inchiKey;
      uniqueId+=generateUniqueId()
      updatedCartItems.push({ ...item,
        quantity: 1,
        priceList: item.priceList,
        id: uniqueId
      });

    });
    setCartItems(updatedCartItems);
  };
  const removeFromCart = (item) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
  };

  const clearCart = () => {
    setCartItems([]);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        addToCartMultiple,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
