 import React, { useEffect, useState, useContext} from 'react';
import { API, Auth } from "aws-amplify";
import { Button, Form, Select, Space, Col, Row, Card, Typography, Modal } from 'antd';
import "./CalculationSetUp.css";
import { onDemandMoleculesContext } from '../context/onDemandMolecules';
import { useNavigate } from "react-router-dom";
import { SmiDrawer } from "smiles-drawer";
import { ToastContainer, toast } from 'react-toastify';
import { SideMenuContext } from '../context/sidemenu';

const {Paragraph} = Typography;

export default function CalculationSetUp(){
    const { onDemandMolecules, clearOnDemandMoleculesList } = useContext(onDemandMoleculesContext)
    const { setSelectedMenuItem } = useContext(SideMenuContext);
    const [dataPCT, setDataPCT] = useState([]);
    const [propCalcMap, setPropCalcMap] = useState({});
    const [loading, setLoading] = useState(false);
    const [calcVersionsList, setcalcVersionsList] = useState([]);
    const [calcVersionSelected, setcalcVersionSelected] = useState([]);
    const [propCalculatorList, setPropCalculatorList] = useState([]);
    const [propCalculatorSelected, setPropCalculatorSelected] = useState([]);

    const [calcMap, setcalcMap] = useState({});


    const nav = useNavigate();
    async function dataPctCalc() {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const request = {
            headers: {
                Authorization: "Bearer " + token
            }
        };

        const jsonDataCalc= await API.get("diadem", "/calculator/list", request);
        let calculatorMap = {};
        let propDict={}
        jsonDataCalc["body"].forEach((item) => {

            if (item.onDemandEnabled === true){
                let providesList= item.provides
                providesList.forEach((propertyItem) => {
                    if (!propDict[propertyItem]) {
                        propDict[propertyItem] = [item.calculatorId];
                      } else {
                        if (!propDict[propertyItem].includes(item.calculatorId)) {
                          propDict[propertyItem].push(item.calculatorId);
                        }}
                });

                // Populate hash map with calculator names as keys and versions list as values
                if (calculatorMap.hasOwnProperty(item.calculatorId)){
                    const tempArr = calculatorMap[item.calculatorId];
                    tempArr.push(item.version);
                }
                else{
                    const tempArr = [];
                    tempArr.push(item.version);
                    calculatorMap[item.calculatorId] = tempArr;
                }
            }

        })
        setPropCalcMap(propDict)
        setDataPCT(Object.keys(propDict))
        setcalcMap(calculatorMap);
        // setDataCalc(Object.keys(calculatorMap));
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [creditsCalculator, setCreditsCalculator] = useState();
    const [onDemandDict, setOnDemandDict] = useState({});
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
      onFinish();
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    async function getCredits(values){
        setLoading(true);
        let jsonCalculationData={"moleculeList":onDemandMolecules,
        "propertyName":values["property"],
        "calculatorId":values["calculator"],
        "version":values["version"]
        }
        setOnDemandDict(jsonCalculationData)
        let jsonCalculator={
                            "calculatorId":values["calculator"],
                            "version":values["version"]
                        }
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body: jsonCalculator
        };
        const jsonCalculatorResponse= await API.put("diadem", "/calculator/credits", request);

        let credits=jsonCalculatorResponse["body"];
        let numberOfMolecules=onDemandMolecules.length
        let totalCredits=numberOfMolecules*credits
        setCreditsCalculator(totalCredits)
        showModal();
        setLoading(false);
    }
    async function onFinish(){
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body: onDemandDict
        };
        const jsonCalculationResponse= await API.put("diadem", "/calculation/ondemandMultiple", request);

        if (jsonCalculationResponse.statusCode===200){
            let batchId=jsonCalculationResponse["body"];
            const batchData = { batchId: batchId, onDemandData: onDemandDict};
            setLoading(false);
            nav("/calculationResult", { state: batchData });
        }
        else{
        const body=JSON.parse(jsonCalculationResponse.body)
        if (body && body.exception){
            toast.error(body.exception);
            setLoading(false);
        }
    }
      };
    // eslint-disable-next-line
    useEffect(() => {
        dataPctCalc(); // eslint-disable-line
        // eslint-disable-line
        SmiDrawer.apply(); // eslint-disable-line
        setSelectedMenuItem("calculationSetUp"); // eslint-disable-line
      }, [onDemandMolecules]); // eslint-disable-line

    const clearMolList = (event) => {
        clearOnDemandMoleculesList();
    };

    const addMolecules = (event) => {
        toast.info("Molecules can be added from search result page or molecule page!");
    };

    const handlePropertyChange = (value) => {
        setPropCalculatorList(propCalcMap[value]);
        setPropCalculatorSelected(propCalcMap[value][0]);
    };



    const handleCalculatorChange = (value) => {
        setPropCalculatorSelected(value);
        setcalcVersionsList(calcMap[value]);
        setcalcVersionSelected(calcMap[value][0]);
    };

    const handleVersionChange = (value) => {
        setcalcVersionSelected(value);
    };

    return (
        <>
            <ToastContainer position="top-center" />
            {loading ? (
                <div class="loading" id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                </div>
            ) : (
                <>
                </>
            )}
            <div className="calculation-setup">
                <h1>New Project</h1>

                <div className="container-up">

                        <Card className="molecule-list" title="Molecule List" bordered={false}>
                            <Row className="cal-result-header">
                                <Col span={6}><b>Molecule</b></Col>
                                <Col span={14}><b>InChIKey and Smiles</b></Col>
                            </Row>
                            <ol type="1">
                                {
                                    onDemandMolecules.map((item, index)=>(
                                        // <li><b>Molecule Id :</b>{item.molId}  <b>Smiles :</b>{item.smiles} <b>Runstatus : </b><Tag color={getBackgroundColor(item.runStatustatus)}>{item.runStatus}</Tag></li>
                                        <li>
                                            <Row>
                                                <Col span={6}><svg data-smiles={item.smiles} /></Col>
                                                <Col span={14} className="id-and-smiles">{item.molId}<br/><Paragraph ellipsis={{ rows: 1, expandable: true }}>{item.smiles}</Paragraph></Col>
                                            </Row>
                                        </li>
                                    ))
                                }
                            </ol>
                            <Space>
                                <Button type="primary" onClick={clearMolList}>
                                    Clear Molecules List
                                </Button>
                                <Button type="primary" onClick={addMolecules}>
                                    Add Molecules
                                </Button>
                            </Space>
                        </Card>
                        {/* <Card className="calculation-details" title="Calculation Details">
                                <div><b>Runtime : </b>X days, X hours</div>
                                <div><b>CPU cost : </b>X hours</div>
                        </Card> */}
                </div>
            <div className="container-up">
                <Card
                    className="calculation-selection"
                    title="Calculation Selection"
                >
                <Form
                    name="basic"
                    labelCol={{
                    span: 8,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    style={{
                    maxWidth: 600,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={getCredits}
                    // onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="property"
                        label="Property"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                            style={{
                                width: 120,
                            }}
                            value={propCalculatorSelected}
                            onChange={handlePropertyChange}
                            placeholder="Select property for calculation"
                            options={dataPCT.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            />
                    </Form.Item>

                    <Form.Item
                        name="calculator"
                        label="Calculator"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                            <Select
                                style={{
                                    width: 120,
                                }}
                                value={propCalculatorSelected}
                                onChange={handleCalculatorChange}
                                placeholder="Select calculator for calculation"
                                options={propCalculatorList.map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            />

                    </Form.Item>

                    <Form.Item
                        name="version"
                        label="Calculator Version"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                                style={{
                                    width: 120,
                                }}
                                placeholder="Select calculator version for calculation"
                                value={calcVersionSelected}
                                onChange={handleVersionChange}
                                options={calcVersionsList.map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            />
                    </Form.Item>
                    <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                    >
                    <Button type="primary" htmlType="submit" >
                        Calculate
                    </Button>
                    <Modal title="Please verify" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <p>This query search will deduct {creditsCalculator} credit. Do you want to proceed? </p>
                    </Modal>
                    </Form.Item>
                </Form>
                </Card>
                </div>
            </div>
        </>
      );
}
