import React from 'react';
import './About.css';
import { MDBIcon } from 'mdb-react-ui-kit';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Diadem is a platform for organic electronics providing a one-stop-shop solution from digital discovery to experimental verification by linking the virtual screening of small molecule candidates with the chemical supply chain.
      </p>
      <p>
        More information is available at <a href="https://www.diadem-project.eu/" rel="noopener noreferrer" className="about-link"> DiaDEM Project
      </a>
      </p>
      <p>
        You can connect with us on LinkedIn at  <a href='https://www.linkedin.com/company/diadem-project/posts/?feedView=all' rel="noopener noreferrer" className='linkedin-link'>
        DiaDEM Project <MDBIcon className="linkedin-icon" fab icon="linkedin" />
        </a>
      </p>

    </div>
  );
};

export default About;
