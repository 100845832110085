import React, {useContext, useEffect} from "react";
import './Support.css';
import { SideMenuContext } from '../context/sidemenu';

export default function Support() {
    const { setSelectedMenuItem } = useContext(SideMenuContext);
    // eslint-disable-next-line
    useEffect(() => {
        setSelectedMenuItem("support"); // eslint-disable-line
    }, []); // eslint-disable-line
    return (
        <div className="container">
        <h1 className="heading">Support</h1>
        <h5>DiaDEM Documentation</h5>
        <p className="text">
            You can find detailed user manuals,
            information on calculators and computed properties along with tutorials on  <a href='https://diadem.readthedocs.io/en/latest/' rel="noopener noreferrer" className='doc-link'>
            DiaDEM Documentation
            </a>.
        </p>
        <h5>Contact</h5>
        <p className="text">
            For assistance, please contact us at diadem-staging@nanomatch.com.
        </p>
        </div>
    );
}
