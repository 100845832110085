import React, { useEffect, useState, useContext } from 'react';
import "./MoleculeSearch.css";
import {  Card, Form, Input,} from 'antd';
import { useNavigate } from 'react-router-dom';
import LoaderButton from '../components/LoaderButton';
import { ToastContainer, toast } from 'react-toastify';
import { SideMenuContext } from '../context/sidemenu';
import "./MoleculeSmilesSearch.css";

const MoleculeSmilesSearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { setSelectedMenuItem } = useContext(SideMenuContext)
    const [smiles, setSmiles] = useState('');
    const nav= useNavigate();
    const { TextArea } = Input;
    useEffect(() => {
        setSelectedMenuItem("search-smiles");
    }, []);// eslint-disable-line

    const searchMolecule = () => {
        setIsLoading(true);

        if (smiles){
            nav("/moleculeSearchView",{state: smiles});
        }
        else{
            setIsLoading(false);
            toast.error("No smiles is submitted")
        }

    };


    return (
        <>
        <ToastContainer position="top-center" />
        <div className='outer-smiles-div'>
        <Card className='smiles-outer-card'>
            <Form  onFinish={searchMolecule}>
                <div className="smiles-heading-div">
                <h3>Please enter smiles to search molecule</h3>
                </div>
                <div className='smiles-input-div'>
                <Form.Item
                >

                    <TextArea
                    className="smile-value-text"
                    rows={4}
                    value={smiles}
                    onChange={(e) => setSmiles(e.target.value)}
                    placeholder="Enter smiles"
                    />
                </Form.Item>
                </div>

                <Form.Item>
                <div className="search-btn-div">
                    <LoaderButton
                    type="primary"
                    isLoading={isLoading}
                    htmlType="submit"
                    className="molecule-search-smiles"
                    >
                    Search for Molecule
                    </LoaderButton>
                    </div>
                </Form.Item>

                </Form>

            </Card>
            </div>


        </>
    );
};

export default MoleculeSmilesSearch;
