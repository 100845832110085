import React, { useEffect, useRef, useState, useContext } from 'react';
import "./MoleculeSearch.css";
import {Button, Card} from 'antd';
import { useNavigate } from 'react-router-dom';
import LoaderButton from '../components/LoaderButton';
import { ToastContainer, toast } from 'react-toastify';
import { SideMenuContext } from '../context/sidemenu';

const MoleculeSearch = () => {
    const viewerRef = useRef(null);
    const [chemViewer, setChemViewer] = useState(null);
    const [smilesCode, setSmilesCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setSelectedMenuItem } = useContext(SideMenuContext)
    const nav= useNavigate();
    useEffect(() => {
        drawMolecule();// eslint-disable-line
        setSelectedMenuItem("search-mol-structure");
    }, [chemViewer]);// eslint-disable-line

    const drawMolecule= () =>{
        if (viewerRef.current && !chemViewer) {
        const viewer = new window.Kekule.Editor.Composer(viewerRef.current);
        viewer.setDimension('100%', '400px');
        viewer.setCommonToolButtons([]);
        viewer.setCommonToolButtons(['newDoc', 'loadData', 'saveData', 'undo', 'redo', 'copy', 'cut', 'paste',
            'zoomIn', 'reset', 'zoomOut'])
        viewer.setChemToolButtons(['manipulate', 'erase', 'bond', 'ring', 'atom']);
        setChemViewer(viewer);
        }
    };
    const generateSmiles = () => {
        if (chemViewer) {
        const chemObj = chemViewer.getChemObj();
        if (chemObj) {
            const smilesCode = window.Kekule.IO.saveFormatData(chemObj, 'smi');
            setSmilesCode(smilesCode);
            return smilesCode
        }
        }
    };

    const searchMolecule = () => {
        setIsLoading(true);
        let smiles= generateSmiles();
        if (smiles){
            nav("/moleculeSearchView",{state: smiles});
        }
        else{
            setIsLoading(false);
            toast.error("No molecule structure is submitted")
        }

    };


    return (
        <>
        <ToastContainer position="top-center" />
            <div className='top-container'>
                <Card className='draw-molecule'>
                    <h3> Draw Molecule</h3>
                        <div className='draw-tool' ref={viewerRef}></div>
                </Card>
                <Card className='generate-smiles'>
                        <h3>Generate smiles from molecule structure</h3>
                        <Button type="primary" onClick={generateSmiles} >
                            Generate Smiles
                        </Button>
                        <div >
                            <textarea
                            value={smilesCode}
                            readOnly
                            className='smiles-text'
                            >

                            </textarea>
                        </div>
                </Card>
            </div>

            <div className="mol-search-btn-container">
                <LoaderButton
                block="true"
                size="lg"
                onClick={searchMolecule}
                isLoading={isLoading}
                className="molecule-search"
                >
                    Search for Molecule
                </LoaderButton>
            </div>

        </>
    );
};

export default MoleculeSearch;
